<template>
    <!-- 管理员- 观思问管理 -->
    <div class="weekly wrapper">
        <proTitle></proTitle>
        <el-form :inline="true" class="demo-form-inline flex-align-between">
            <div class="formInline-left">
                <el-form-item label>
                    <el-input v-model="formInline.search" size="medium" placeholder="搜索" suffix-icon="el-icon-search" @input="phaseUserList()">
                    </el-input>
                </el-form-item>
            </div>
            <div class="formInline-right flex-align-between download-inline">
                <!-- <span class="export-btn csp" style="margin-right:10px">
                    <i class="el-icon-more"></i>查看二维码</span> -->
                <span class="export-btn csp" @click="exportGsw">
                    <i class="export-icon"></i>导出</span>
            </div>
        </el-form>
        <div class="table_section">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="number" label="序号" width="80" align="center" type="index" :index="indexMethod">
                </el-table-column>
                <el-table-column prop="userName" label="教师姓名" align="center">
                </el-table-column>
                <el-table-column prop="accompanyCount" label="跟岗培训" align="center">
                </el-table-column>
                <el-table-column prop="gatherCount" label="集中培训" align="center">
                </el-table-column>
                <el-table-column prop="backCount" label="返岗实践" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button @click="editFile(scope)" type="text" size="small">查看
                        </el-button>
                    </template>
                </el-table-column>

            </el-table>
            <pagination :total="pages.total" :page.sync="pages.currentPage" :limit.sync="pages.pageSize"
                :pageSizes="[10, 20, 30]" @pagination="handlePageChange">
            </pagination>
        </div>
        <!-- :before-close="closeDialog" -->
        <el-dialog :title="dialogTitle" :visible.sync="showCreateDialog" width="600px" @close="closeDialog"
            destroy-on-close>
            <el-form ref="createForm" :model="createForm" label-width="120px" :rules="rules">
                <el-form-item label="文件名称：" prop="name">
                    <el-input v-model.trim="createForm.name" size="mini" style="width:220px;" placeholder="请输入标题"
                        maxlength="50" show-word-limit></el-input>
                </el-form-item>

                <el-form-item label="上传附件：" prop="identifier">
                    <upload @uploadFile="handleFileChange" :name="'identifier'" :list="editIdentifierList" :text="'上传周报'" />
                </el-form-item>

                <el-form-item class="btns-box" label-width="0px">
                    <el-button class="submit btn" @click="onSubmit">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import upload from './components/upload-file.vue';
import {
    cloneDeep
} from 'lodash/lang'

export default {
    components: {
        upload
    },
    data() {
        return {
            activeName: "",
            rules: {
                name: {
                    required: true,
                    message: "请输入文件名称",
                    trigger: "blur"
                },
            },
            tableData: [],
            pages: { //分页
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            showCreateDialog: false,
            createForm: {
                name: undefined,
                identifier: undefined,
                type: 4,
            },
            dialogTitle: '上传周报',
            editIdentifierList: [],
            formInline: {},
        };
    },
    methods: {
        indexMethod(index) {
            return this.pages.currentPage > 1 ?
                (this.pages.pageSize * (this.pages.currentPage - 1)) + index + 1 :
                index + 1
        },
        // 上传文件
        handleFileChange(data) {
            // console.log(data);
            this.createForm[data.name] = data.fileMD5List[0];
            Vue.delete(this.createForm, 'fileName');
            if (JSON.stringify(data.fileMD5List) != '[]') {
                Vue.set(this.createForm, 'fileName', data.fileList[0].name);
            }
        },
        // 翻页
        handlePageChange(data) {
            this.pages.currentPage = data.pageIndex
            this.pages.pageSize = data.pageSize
            // this.getWeeklyList();
            this.phaseUserList()
        },
        // 获取周报列表
        async getWeeklyList() {
            let pramas = {
                page: this.pages.currentPage,
                limit: this.pages.pageSize,
                projectId: this.$store.state.user.currentProject.id,
                type: 4,
            }
            let resData = await this.$Api.Project.getProjectFileList(pramas);
            // console.log(resData);
            this.tableData = resData.data.records
            this.pages.total = resData.data.total
        },
        // 关闭上传弹窗
        closeDialog() {
            this.editIdentifierList = [];
            this.dialogTitle = '上传周报'
            Vue.set(this.createForm, 'name', undefined);
            Vue.set(this.createForm, 'identifier', undefined);
            Vue.delete(this.createForm, 'id');
            Vue.delete(this.createForm, 'createTime');
            Vue.delete(this.createForm, 'fileName');
        },
        // 编辑
        editFile(scope) {
            this.$router.push(`/observe/${scope.row.id}?breadNumber=4`)
        },
        onSubmit() {
            this.$refs['createForm'].validate((valid) => {
                // console.log('valid',valid);
                if (valid) {
                    this.submitForm();
                } else {
                    console.log('error')
                }
            })
        },
        // 表单提交
        async submitForm() {
            let msg = undefined;
            let type = undefined;
            // 新建周报
            if (this.dialogTitle == '上传周报') {
                let resData = await this.$Api.Project.addProjectFile(this.createForm);
                // console.log('add',resData);
                msg = resData.code == 200 ? '上传成功' : '上传失败';
                type = resData.code == 200 ? 'success' : 'error';
            }
            // 编辑周报
            if (this.dialogTitle != '上传周报') {
                let resData = await this.$Api.Project.editProjectFile(this.createForm);
                // console.log('edit',resData);
                msg = resData.data.code == 200 ? '编辑成功' : '编辑失败';
                type = resData.data.code == 200 ? 'success' : 'error';
            }
            this.$message({
                message: msg,
                type: type
            });
            this.getWeeklyList();
            this.showCreateDialog = false;
            return;
        },
        // 删除
        deleteProjectFile(item) {
            this.$confirm(`是否删除${item.name}？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let params = {
                        id: item.id
                    }
                    console.log(params);
                    this.$Api.Project.deleteProjectFile(params).then((resData) => {
                        console.log(resData);
                        if (resData.code == 200) {
                            this.$message({
                                message: "删除成功",
                                type: "success"
                            });
                            this.getWeeklyList();
                        } else {
                            this.$message.error(resData.msg);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },


        // 获取观思问列表
        async phaseUserList() {
            let data = {
                page: this.pages.currentPage,
                limit: this.pages.pageSize,
                projectId: this.$store.state.user.currentProject.id,
                queryName: this.formInline.search
            }
            let resData = await this.$Api.kimi.phaseUserList(data);
            // console.log('观思问列表:', resData);
            this.tableData = resData.data.records
            this.pages.total = resData.data.total
        },
        // 导出观思问
        async exportGsw() {
            // let data = {
            //     projectId: this.$store.state.user.currentProject.id,
            // }
            // let resData = await this.$Api.kimi.exportGsw(data);
            window.location.href = encodeURI(axios.defaults.baseURL + `/course/phase/export?projectId=${this.$store.state.user.currentProject.id}`)
        }
    },
    mounted() {
        // this.getWeeklyList();
        Vue.set(this.createForm, 'projectId', this.$store.state.user.currentProject.id);
        this.phaseUserList()
    },
}
</script>

<style lang="less" scoped>
@import "../manage/less/table.less";

.weekly {
    padding: 20px 25px;
    box-sizing: border-box;

    .download-inline {
        .export-btn {
            color: #595959;
        }

        .export-icon {
            display: inline-block;
            background-image: url("~@/assets/images/admin/export.png");
            background-size: 100% 100%;
            width: 17px;
            height: 16px;
            margin-right: 8px;
            vertical-align: -2px;
        }
    }

    /deep/ .el-dialog {
        border-radius: 10px;

        .el-dialog__header {
            border-bottom: 1px solid #E4E4E4;
            padding: 15px 31px 10px;
        }

        .el-dialog__title {
            font-size: 15px;
            color: #333;

            &::before {
                content: '';
                width: 5px;
                height: 17px;
                background-color: #01A2FD;
                display: inline-block;
                margin-right: 14px;
                vertical-align: -3px;
                border-radius: 3px;
            }
        }

        .el-dialog__headerbtn {
            top: 15px;
        }

        .btns-box {
            text-align: center;
        }

        .submit {
            background: linear-gradient(180deg, #5DA7FF 0%, #4893F9 100%);
            border-radius: 25px;
            color: #fff;
            line-height: 35px;
            padding: 0 63px;
            cursor: pointer;
            border: 0;

            &:hover {
                background: linear-gradient(180deg, rgba(93, 167, 255, .8) 0%, rgba(72, 147, 249, .8) 100%);
            }
        }
    }

    .table_section {
        margin-top: 15px;
    }
}
</style>
